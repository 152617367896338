.navbar-brand {
  >img {
    height: 100%;
    width: auto;
  }
  height: 100px;
  padding: 10px 15px 5px;

  @media screen and (max-width: 768px ) {
    height: 80px;
    padding: 15px 0px;
  }
  @media (max-width:768px) {
    padding: 10px 0px;
    margin-left: -15px;
  }
  @media (max-width:380px) {
    padding: 15px 0px;
    margin-left: -20px;
  }
}