// Grid system
.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}

/*
 * START CUSTOM BREAKPOINT
 */

// Framework grid generation
@mixin make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-is-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xs-#{$i}, .col-is-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left:  ceil(($grid-gutter-width / 2));
    padding-right: floor(($grid-gutter-width / 2));
  }
}

// Generate the intermediate small columns
@mixin make-is-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-is-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-is-column-offset($columns) {
  @media (min-width: $screen-is-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-is-column-push($columns) {
  @media (min-width: $screen-is-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-is-column-pull($columns) {
  @media (min-width: $screen-is-min) {
    right: percentage(($columns / $grid-columns));
  }
}

// Columns
@include make-grid-columns;

// Extra small grid
@include make-grid(xs);

// Intermediate grid
@media (min-width: $screen-is-min) {
  @include make-grid(is);
}

// Small grid
@media (min-width: $screen-sm-min) {
  @include make-grid(sm);
}

// Medium grid
@media (min-width: $screen-md-min) {
  @include make-grid(md);
}

// Large grid
@media (min-width: $screen-lg-min) {
  @include make-grid(lg);
}

// Visibility utilities
@include responsive-invisibility('.visible-is');

.visible-is-block,
.visible-is-inline,
.visible-is-inline-block {
  display: none !important;
}

@media (min-width: $screen-is-min) and (max-width: $screen-is-max) {
  @include responsive-visibility('.visible-is');
}
.visible-is-block {
  @media (min-width: $screen-is-min) and (max-width: $screen-is-max) {
    display: block !important;
  }
}
.visible-is-inline {
  @media (min-width: $screen-is-min) and (max-width: $screen-is-max) {
    display: inline !important;
  }
}
.visible-is-inline-block {
  @media (min-width: $screen-is-min) and (max-width: $screen-is-max) {
    display: inline-block !important;
  }
}

@media (min-width: $screen-is-min) and (max-width: $screen-is-max) {
  @include responsive-invisibility('.hidden-is');
}

/*
 * END CUSTOM BREAKPOINT
 */
