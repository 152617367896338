body {
	//font-family: 'Muli', sans-serif;
	font-family: 'Questrial', sans-serif;
  font-size:20px;
  // overflow-x:hidden;
}

nav li {
    display: inline;
    float: left;
}

a {
    color: #1C7D75;
    text-decoration: none;
    /* font-weight: bold; */
}

nav {
    float: right;
    margin-top: 28px;
}

.nav>li>a {
    position: relative;
    display: block;
    font-size: 16px;
    padding: 10px 15px;
}
@media (max-width:1200px) {
    .nav>li>a {
        padding: 10px;
    }
}

.nav>li>a:focus, .nav>li>a:hover, .nav>li.active a {
    text-decoration: none;
    background-color: #1C7D75;
    color: white;
}

/************* Superfly MENU **************/

.sfm-rollback {
    display:none;
    position: absolute !important;
}

.sfm-navicon-button {
    top: 24px !important;
    right: 25px !important;
}

@media (max-width:1023px) {
    .sfm-rollback {display:block;}
}
@media (max-width:768px) {
    .sfm-navicon-button {
        top: 14px !important;
        right: 10px !important;
    }
}
@media (max-width:768px) {
    .sfm-navicon-button {
        left: -25px !important;
    }
}
@media (max-width:380px) {
    .sfm-navicon-button {
        right: 0px !important;
        left: -15px !important;
    }
}

/************* Superfly MENU END **********/

.h1, h1 {
    font-size: 42px;
}

.ls-wp-fullwidth-container {
    margin-top: -75px;
}

.wrap {
    /* background-color: #7CB3AE; */
    border-top: 5px solid #1C7D75; /* #7cB3aE; */
    // border-bottom: 5px solid #7cb3ae;
    padding-bottom:50px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e7f0f5+0,ffffff+100 */
background: #e7f0f5; /* Old browsers */
background: -moz-linear-gradient(top,  #e7f0f5 0%, #ffffff 100%); /* FF3.6+ */
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#e7f0f5), color-stop(100%,#ffffff)); /* Chrome,Safari4+ */
background: -webkit-linear-gradient(top,  #e7f0f5 0%,#ffffff 100%); /* Chrome10+,Safari5.1+ */
background: -o-linear-gradient(top,  #e7f0f5 0%,#ffffff 100%); /* Opera 11.10+ */
background: -ms-linear-gradient(top,  #e7f0f5 0%,#ffffff 100%); /* IE10+ */
background: linear-gradient(to bottom,  #e7f0f5 0%,#ffffff 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e7f0f5', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

}

.home .wrap {
    border-bottom: 0;
    border-top: 5px solid #1C7D75; /* #7cb3ae; */
    padding-top: 70px;
    @media (max-width: 767px) {
    	padding-top:0;
    }
}

div#copyright {
    font-size: 14px;
    text-align: center;
    /* border-top: 3px solid #1C7D75; */
    color: white;
    padding-top: 10px;
    margin-top: -10px;
    background-color: #1c7d75;
    padding-bottom: 10px;
}

div#wpadminbar {
    display: none;
}

.home h1, .home .page-header {
    display: none;
}
.theWortSubtitle {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 10px;
}

.page-header {
    text-align: center;
    position: absolute;
    z-index: 10000;
    border-bottom: 0px;
}
.page-header h1 {
    background-color: #1C7D75; /* #7CB3AE; */
    margin: 0 auto;
    display: table-cell;
    padding: 10px 20px;
    color: #fff;
    position: absolute;
    margin-top: -44px;
    margin-left: 70px;

    white-space: nowrap;

    @media (max-width: 1024px) {
        margin-left:50px;
    }
    @media (max-width: 768px) {
        margin-left:-10px;
        font-size: 1.4em;
    }
}

.bricks-googlemap {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
    width: 103%;
}

/*
.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 0px;
    padding-right: 0px;
}*/

.owl-prev {
    color: #F25056;
    margin-left: 15px;
    position: absolute;
    font-size: 40px;
     top: 50%;
    transform: translateY(-50%);
    cursor:pointer;
}

.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 1;
    padding: 10px;
    background-color: white;
}

.owl-next {
    color: #F25056;
    position: absolute;
    right: 45px;
    font-size: 40px;
    top: 50%;
    transform: translateY(-50%);
    cursor:pointer;
}

.owl-navContainer {
    position: absolute;
    top: 50%;
    z-index: 1000;
    /* padding: 10px; */
    width: 100%;
    transform: translateY(-50%);
}

.dd_post_share {
    padding: 0;
    margin-bottom: 6px;
    display: block;
    width: 270px;
    margin: 0 auto;
    margin-top: 50px;
    border-top: 5px solid #7AB3AE;
    padding-top: 10px;
}

.page-header h1:after {
    content: "";
    position: absolute;
    top: 100%;
    border-top: 16px solid #1C7D75; /* #7CB3AE; */
    border-left: 10px solid transparent;
    border-right: 110px solid transparent;
    width: 10px;
    height: 10px;
    left: 0;
}

.anno {
    text-align: center;
    font-size: 44px;
    margin-top: 40px;
    border-bottom: 2px solid #1C7D75;
    margin-bottom: 30px;
    color: #1C7D75;
}

.danno {
    text-align: center;
    font-size: 44px;
    margin-top: 40px;

    margin-bottom: 10px;
    color: #1C7D75;
}

.descWork {
    margin-top:20px;
}

.titoloBox h2 {
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    font-size: 1em;
    margin: 0;
    @media (max-width:1023px) {
        font-size: .8em;
    }
}


.titoloBox h3 {
    margin: 20px 0px 0px !important;
    font-size: 18px;
    background-color: transparent;
    display: inline-block;
    padding: 10px 20px;
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    margin: 0;
    color: white;
    border: 3px solid white;
    @media (max-width:1023px) {
        font-size: .8em;
    }
}




/* Loader gallery homepage */
.ls-ct-half {
   background: #1C7D75;
}

/*********** HOME **************/

.home .home-skills {
	@media (max-width:1024px) {
		display: block;
	}
}

/*********** AZIENDA ***********/

.azienda-img-margin-bottom {
    margin-bottom:20px;
}

/********* SERVIZI ***********/

.servizi .flow-text h2 {
	margin-top:0px;
}

/********* CONTATTI **********/

.mappa-google {
    margin-left:15px;
    padding-top:30px;
}

.form-group .btn-primary {
    background: #1C7D75;
}

/*****************************/

footer {
    position: relative;
    z-index: 1;
}